import React, {FC} from "react";

const Stats: FC<{ appData: any }> = ({ appData }) => {
  return (
    <div className="rounded-xl text-white p-2 w-full flex flex-row justify-around">
      <div>
        <p className="text-center text-2xl pb-1">
          {appData.activeUsers}
        </p>
        <p className="text-center secondary uppercase text-xs">
          Connected<br/>Now
        </p>
      </div>
      <div>
        <p className="text-center text-2xl pb-1">
          {appData.totalUsers}
        </p>
        <p className="text-center secondary uppercase text-xs">
          Connected<br/>Today
        </p>
      </div>
      <div>
        <p className="text-center text-2xl pb-1">
          {appData.totalCountries}
        </p>
        <p className="text-center secondary uppercase text-xs">
          Countries<br/>
          Today
        </p>
      </div>
    </div>
  )
}

export default Stats;