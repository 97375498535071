import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { HiOutlineLanguage } from "react-icons/hi2";
import { API_URL } from "../utils/consts";
import { Store, useStore } from "../store";


export const languageMapping = {
  "en": { name: "English", code: 'EN' },
  "es": { name: "Español", code: 'ES' },
  "fr": { name: "Français", code: 'FR' },
  "pl": { name: "Polski", code: 'PL' },
} as { [key: string]: { name: string, code: string } }

const Language: FC<{
  fullWidth?: boolean,
  withLabel?: boolean
}> = ({ fullWidth, withLabel }) => {
  const { i18n } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const [open, setOpen] = useState(fullWidth);
  const { language } = userData;

  const handleChange = (language: string) => {
    if (open) {
      setUserData({ ...userData, language })
      i18n.changeLanguage(language);
      axios.patch(`${API_URL}/users/${userData.id}/language`, { language })
        .catch((err) => console.log(err))

      if (!fullWidth) {
        setOpen(false)
      }
    }
  }

  return(
    <>
      {withLabel && (
        <div className="flex justify-center items-center mb-4">
          <HiOutlineLanguage size={18} />
          <p className="pl-2">Language</p>
        </div>

      )}

      <div className="flex rounded-full justify-center items-center">
        <div className="overflow-hidden flex flex-1 justify-between items-center transition-all duration-500" style={{ width: open || fullWidth ? 250 : 40 }}>
          {!fullWidth && (
            <button className="btn circle" onClick={() => setOpen(prev =>  !prev)}>
              <HiOutlineLanguage size={22} />
            </button>
          )}

          {["en", "es", "fr", "pl"].map(lang =>
            <button
              key={lang}
              onClick={() => handleChange(lang)}
              className="btn circle transition-all duration-500"
              style={{
                opacity: open ? "100%" : "0%",
                width: open ? "40px" : "0px",
                visibility: open ? "visible" : "hidden",
                fontWeight: language === lang ? "bold" : "normal",
                color: language === lang ? 'white' : 'rgba(255, 255, 255, 0.7)'
              }}
            >
              {languageMapping[lang].code}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default Language;