import React, { FC, useMemo, } from "react";
import axios from "axios";
import { isNull } from "lodash";
import { useTranslation } from 'react-i18next'
import { API_URL } from "../../utils/consts";
import {Store, useStore} from "../../store";
import AudioControls from "./audioControls";

const Meditation: FC= () => {
  const { t } = useTranslation();

  const goToPage = useStore((state: Store) => state.goToPage)
  const appData = useStore((state: Store) => state.appData)
  const userData = useStore((state: Store) => state.userData)
  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)

  const intention = useMemo(
    () => userData.userIntentions.find(ui => isNull(ui.endTime)),
    [userData.userIntentions]
  );

  const finish = () => {
    if (!intention || userData.id === "null" ) {
      setUserData({ ...userData, userIntentions: [{ ...userData.userIntentions[0], endTime: new Date() }] })
      goToPage("summary", 0)
      return
    }

    axios.patch(`${API_URL}/user_intentions/${intention.id}/finish`, { user_id: userData.id  })
      .then(response => {
        setAppData({ ...appData, activeUsers: appData.activeUsers - 1 })
        const finishedIntention = response.data;
        const intentionIndex = userData.userIntentions.findIndex(i => i.id === finishedIntention.id)
        const userIntentions = [userData.userIntentions.slice(0, intentionIndex), finishedIntention, userData.userIntentions.slice(intentionIndex + 1)];
        setUserData({ ...userData, userIntentions })
        goToPage("summary", finishedIntention.id);
      })
      .catch(err => console.log(err));
  }

  return(
    <div className="page h-full">
      <div className="flex flex-1 flex-col justify-center items-center h-full w-full">
        {appData.intention && (
          <p className="my-12 sacramento">
            {appData.intention.text}
          </p>
        )}

        <div className="w-full my-8">
          <AudioControls />
        </div>
      </div>

      <button onClick={finish} className="btn primary mb-4  ">
        Finish
      </button>
    </div>
  );
}

export default Meditation;