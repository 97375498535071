import React, {FC, useState} from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogBackdrop } from "@headlessui/react";
import { CiUser } from "react-icons/ci";
import { RiCloseFill } from "react-icons/ri";
import Language  from "./Language";
import Country from "./Country";

const Settings: FC = () => {
  const { t, i18n } = useTranslation()

  const [open, setOpen] = useState(false);

  return(
    <>
      <div className="absolute top-2 right-2 md:top-4 md:right-4">
        <button className="btn circle" onClick={() => setOpen(true)}>
          <CiUser size={28} />
        </button>
      </div>

      <Dialog open={open} onClose={() => setOpen(false)} className="relative z-50">
        <DialogBackdrop className="fixed inset-0 background-app" />
        <div className="flex flex-col fixed justify-center items-center inset-0 m-2 md:m-4">
          <button onClick={() => setOpen(false)} className="btn circle self-end">
            <RiCloseFill size={24} />
          </button>

          <div className="flex-1 flex flex-col items-center justify-center">
            <div className="flex flex-col justify-center items-center">
              <Language fullWidth withLabel />
            </div>

            <div className="flex flex-col justify-center items-center mt-16">
              <Country />
            </div>
          </div>

          <div className="flex justify-center">
            <button className="btn secondary mb-4" onClick={() => setOpen(false)}>
              OK
            </button>
          </div>
        </div>
      </Dialog>
    </>
  )
};

export default Settings;