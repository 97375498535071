import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import moment from "moment";
import { useTranslation } from 'react-i18next'
import { RiMusic2Line, RiUserVoiceLine } from "react-icons/ri";
import { IoPause, IoPlay } from "react-icons/io5";
import musicSrc from "../../assets/track.mp3";
import guideSrc from "../../assets/guide.mp3";

const AudioControls: FC= () => {
  const { t } = useTranslation();

  const playAnimationRef = useRef<number>(0);

  const [musicOn, setMusicOn] = useState(false);
  const [guideOn, setGuideOn] = useState(false);
  const [guideDuration, setGuideDuration] = useState(0);
  const [durationProgress, setDurationProgress] = useState(0);

  const musicAudio = useMemo(() => new Audio(musicSrc), []);
  const guideAudio = useMemo(() => new Audio(guideSrc), []);

  useEffect(() => {
      musicOn ? musicAudio.play() : musicAudio.pause();
    },
    [musicOn, musicAudio]
  );

  useEffect(() => {
      if (guideOn) {
        guideAudio.play()
        playAnimationRef.current = requestAnimationFrame(animate);
      } else {
        guideAudio.pause();
        cancelAnimationFrame(playAnimationRef.current);
      }
    },
    [guideOn, guideAudio]
  );

  useEffect(() => {
    musicAudio.loop = true;

    return () => {
      setMusicOn(false)
      musicAudio.pause();
    };
  }, [musicAudio]);

  useEffect(() => {
    const handleMetadata = () => {
      setGuideDuration(guideAudio.duration)
    }

    const handleEnded = () => {
      guideAudio.currentTime = 0;
      setGuideOn(false);
      setDurationProgress(0);
    }

    guideAudio.addEventListener("loadedmetadata", handleMetadata)
    guideAudio.addEventListener("ended", handleEnded)

    return () => {
      guideAudio.removeEventListener("loadedmetadata", handleMetadata);
      guideAudio.removeEventListener("ended", handleEnded);
      setGuideOn(false)
      guideAudio.pause();
    };
  }, [guideAudio]);

  const animate = () => {
    setDurationProgress(guideAudio.currentTime)

    playAnimationRef.current = requestAnimationFrame(animate);
  };

  const toggleMusic = () => {
    setMusicOn(!musicOn)
  }

  const toggleGuide = () => {
    setGuideOn(!guideOn)
  }

  const setGuideCurrentTime = useMemo(
    () => debounce((seconds: number) => {guideAudio.currentTime = seconds }, 200),
    [guideAudio]
  );

  const handleGuideSeek = (seconds: number) => {
    setDurationProgress(seconds)
    setGuideCurrentTime(seconds)
  }

  return(
    <div className="flex w-full">
      <div className="flex items-center bg-[rgba(0,0,0,0.15)] rounded-full w-full">
        <button className={`btn circle big ${guideOn ? 'primary' : ''}`} onClick={toggleGuide} aria-label={`${guideOn ? "Pause" : "Play"} guided meditation`}>
          <RiUserVoiceLine size={20} className="mr-1" />
          {guideOn ?  <IoPause size={12} /> : <IoPlay size={12} />}
        </button>

        <div className="flex items-center mx-2 flex-1">
          <span className="text-sm min-w-[40px]">{moment.utc(durationProgress*1000 + 500).format('mm:ss')}</span>
          <input
            type="range"
            min="0"
            max={guideDuration}
            step={0.1}
            value={durationProgress}
            onChange={e => handleGuideSeek(parseInt(e.target.value))}
            className="touch-none w-full h-1 -my-[4px] mx-2 rounded-lg cursor-pointer"
            style={{ accentColor: guideOn ? "rgb(243,179,71)" : "#ccc" }}
          />
          <div className="text-sm">
            {moment.utc(guideDuration*1000).format('mm:ss')}
          </div>
        </div>
      </div>

      <div className="mx-2"></div>

      <div className="flex items-center bg-[rgba(0,0,0,0.15)] rounded-full">
        <button className={`btn circle big ${musicOn ? 'primary' : ''}`} onClick={toggleMusic} aria-label={`${musicOn ? "Pause" : "Play"} background music`}>
          <RiMusic2Line size={20} className="mr-1" />
          {musicOn ?  <IoPause size={12} /> : <IoPlay size={12} />}
        </button>
      </div>
    </div>
  );
}

export default AudioControls;