import React, { FC, MouseEventHandler } from "react";
import IntroPage from "./IntroPage";
import { useTranslation } from "react-i18next";
import Infinity from "../../components/shapes/Infinity"

const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t, i18n } = useTranslation()

  return(
    <IntroPage>
      <div className="flex flex-1 flex-col w-full content-center justify-center">
        <p className="text-center mb-8 sacramento">
          Dive in
        </p>

        <Infinity height="100px" className="pathShadow mb-8" />

        <p className="text-center mb-8">
          Each day, we offer a new intention to reflect on.
        </p>
        <p className="text-center mb-16">
          An audio guide or a subtle background track can help you immersing in a contemplative meditation.
        </p>
      </div>

      <div className="flex justify-center">
        <button onClick={onNext} className="btn secondary">
          Next
        </button>
      </div>
    </IntroPage>
  )
};

export default Step1;