import React, {useMemo} from "react";
import * as d3 from 'd3';
import { geoData, MapNumDataType, numData } from "../utils/geo";
import {Store, useStore} from "../store";

const OPACITY_MIN_SIZE = 0.2;
const OPACITY_MAX_SIZE = 0.8;

type MapProps = {
  width: number;
  height: number;
  userCountryCode?: string;
};

const Map = ({ width, height, userCountryCode }: MapProps) => {
  const appData = useStore((state: Store) => state.appData)
  const stringifiedActiveUsersPerCountry = JSON.stringify(appData.activeUsersPerCountry);

  const countryOpacity = useMemo(
    () => {
      const newNumData = JSON.parse(JSON.stringify(numData)) as MapNumDataType[];
      JSON.parse(stringifiedActiveUsersPerCountry).forEach((countryActiveUsers: { country: string, activeUsers: number}) => {
        const element = newNumData.find((d) => d.name === countryActiveUsers.country)
        if (element) {
          element.value = Number(countryActiveUsers.activeUsers)
        }
      })

      const [min, max] = d3.extent(newNumData.map((d) => d.value)) as [number, number];

      const opacityScale = d3
        .scaleSqrt()
        .domain([min, max])
        .range([OPACITY_MIN_SIZE, OPACITY_MAX_SIZE]);

      const newCountryOpacity = {} as { [key: string]: number };
      newNumData.forEach(region => {
        const regionGeoData = geoData.features.find(
          (geoRegion: any) => geoRegion.id === region.code
        );
        if (regionGeoData && regionGeoData.id && region.value > 0) {
          newCountryOpacity[String(regionGeoData.id)] = opacityScale(region.value);
        }
      })

      return newCountryOpacity
    },
    [stringifiedActiveUsersPerCountry]
  )

  // const userRegionGeoData = geoData.features.find(
  //   (geoRegion: any) => geoRegion.properties.name === userCountryCode
  // );

  const projection = d3
    .geoMercator()
    .scale(width / 2.3 / Math.PI)
    .center([0, 45])
    .translate([width/2, height/2]);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const allCountryPaths = geoData.features
    .filter((shape: any) => shape.id !== 'ATA')
    .map((shape: any) => {
      return (
        <path
          key={shape.id}
          className="transition-[fill-opacity] duration-1000"
          d={geoPathGenerator(shape) || undefined}
          fill="white"
          fillOpacity={countryOpacity[String(shape.id)] || 0.1}
        />
      );
    });

  return (
    <svg width={width} height={height}>
      <defs>
        <radialGradient id="myGradient">
          <stop offset="30%" stopColor="white" />
          <stop offset="100%" stopColor="white" stopOpacity={0} />
        </radialGradient>
      </defs>
      {allCountryPaths}
      {/*{allCircleShapes}*/}
    </svg>
  );
};

export default Map;