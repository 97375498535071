import React, {FC, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { WiStars } from "react-icons/wi";
import { Store, useStore } from "../../store";
import Language from "../../components/Language";
import IntroPage from "./IntroPage";
import Country from "../../components/Country";


const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t, i18n } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const { country } = userData;

  return(
    <IntroPage>
      <div className="flex justify-center">
        <Language />
      </div>

      <div className="flex flex-1 flex-col w-full content-center justify-center">
        <p className="text-center mb-8 sacramento">
          Let’s bring light and hope together
        </p>

        <p className="text-center mb-8">
          Every day, unite with the Higher Power and people worldwide in a shared intention to uplift and inspire through&nbsp;meditation.
        </p>

        {/*<div className="flex justify-center my-8">*/}
        {/*  <WiStars size={48} className="pathShadow" fillOpacity={0} strokeWidth={0.5} style={{ color: "rgba(255, 255, 255, 0.9)"}} />*/}
        {/*</div>*/}

        <div className="flex flex-col items-center justify-center">
          <Country />
        </div>
      </div>

      <div className="flex justify-center">
        <button onClick={onNext} disabled={!country} className="btn secondary">
          Next
        </button>
      </div>
    </IntroPage>
  )
};

export default Step1;