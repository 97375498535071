import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Store, useStore } from "../../store";
import { API_URL } from "../../utils/consts";

type SummaryData = {
  meditationTime: number;
  otherUsersCount: number;
  otherUsersTime: number;
  otherUsersCountriesCount: number;
  daysStrike: number;
}

const Summary = () => {
  const page = useStore((state: Store) => state.page)
  const goToPage = useStore((state: Store) => state.goToPage)
  const userData = useStore((state: Store) => state.userData)

  const [summaryData, setSummaryData] = useState<SummaryData | undefined>();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (userData.id === "null") {
      const userIntention = userData.userIntentions[0];
      setSummaryData({
        meditationTime: ((userIntention.endTime || new Date()).getTime() - userIntention.startTime.getTime()) / 1000,
        otherUsersCount: 0,
        otherUsersTime: 0,
        otherUsersCountriesCount: 0,
        daysStrike: 0,
      })
      setLoading(false)

      return;
    }

    axios.get(`${API_URL}/user_intentions/${page.userIntentionId}/summary?user_id=${userData.id}`)
      .then(response => setSummaryData(response.data))
      .catch((err) => console.log(err))
      .finally(() => { setLoading(false) })
  }, [page.userIntentionId, userData.id]);

  if (loading) {
    return(
      <div className="page h-full flex-1 justify-center items-center">
      </div>
    )
  }

  if (!summaryData) {
    return (
      <div className="page h-full  flex-1 justify-center items-center">
        <p>Something wen't wrong :(</p>
      </div>
    )
  }

  // const meditationTime = moment.duration(summaryData.meditationTime, 'seconds')
  // const summedMeditationTime = moment.duration(summaryData.meditationTime + summaryData.otherUsersTime, 'seconds')
  // const othersMeditationTime = moment.duration(summaryData.otherUsersTime, 'seconds')

  return (
    <div className="page h-full w-full">
      <div className="flex flex-col flex-1 justify-center items-center w-full">
        <p className="mt-16 sacramento">We're truly grateful</p>

        {!loading && summaryData && (
          <div className="my-12 w-full max-w-[460px]">
            <div className="relative border rounded-full aspect-square flex flex-col justify-center items-center mx-4">
              <div className="w-full h-full border border-[rgba(255,255,255,0.3)] rounded-full absolute top-[10px] left-[10px]" />
              <div className="w-full h-full border border-[rgba(255,255,255,0.3)] rounded-full absolute top-[4px] -left-[12px]" />

              <div className="p-4 flex flex-col items-center">
                {step === 0 && (
                  <>
                    <p className="text-lg">
                      You've been meditating for
                    </p>
                    <p className="text-2xl">
                      {Math.floor(summaryData.meditationTime / 60)} minutes
                    </p>
                  </>
                )}

                {step === 1 && summaryData.otherUsersCount > 0 && (
                  <>
                    <p>
                      There were
                    </p>
                    <p>
                      <span className="text-2xl">{summaryData.otherUsersCount} other people</span>{' '}
                      {summaryData.otherUsersCount > 1 && (
                        <>
                          from{' '}
                          <span className="text-2xl">{summaryData.otherUsersCountriesCount} countries</span>
                        </>
                      )}
                    </p>
                    <p>
                      in this intention with you
                    </p>
                  </>
                )}
                {step === 1 && summaryData.otherUsersCount === 0 && (
                  <div className="p-4">
                    <p>This time you were the only person online. Awesome, you've marked your presence!</p>
                  </div>
                )}


                {step === 2 && (
                  <>
                    <p className="mb-2">Meditation or prayer has a positive impact<br /> on You and the whole World.</p>
                    <p className="text-2xl">Keep it up!</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <button className="btn primary mb-4" onClick={() => { step === 2 ? goToPage("home") : setStep(step + 1) }}>
          {step === 2 ? 'Home' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default Summary;