import React, { FC, MouseEventHandler } from "react";
import IntroPage from "./IntroPage";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {API_URL} from "../../utils/consts";
import {Store, useStore} from "../../store";

const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t, i18n } = useTranslation()

  const goToPage = useStore((state: Store) => state.goToPage)
  const userData = useStore((state: Store) => state.userData)
  const setUserData = useStore((state: Store) => state.setUserData)

  const start = () => {
    setUserData({ ...userData, introCompleted: true })
    if (userData.id !== "null") {
      axios.patch(`${API_URL}/users/${userData.id}/complete_intro`)
        .then(() => goToPage("home"))
        .catch((err) => console.log(err))
    } else {
      goToPage("home")
    }
  };

  return(
    <IntroPage>
      <div className="flex flex-1 flex-col w-full content-center justify-center">
        <p className="text-center sacramento">
          Feel connected to something greater
        </p>

        <div className="flex flex-col w-full justify-center items-center relative h-[84px] my-16">
          <div className="w-[84px] h-[84px] rounded-full border-white absolute top-[0px] left-[calc(50%-68px)]" style={{ boxShadow: "0px 0px 5px 1px #fff" }} />
          <div className="w-[68px] h-[68px] rounded-full border-white absolute top-[10px] left-[calc(50%-18px)]" style={{ boxShadow: "0px 0px 5px 1px #fff" }} />
        </div>

        <p className="text-center mb-16">
          Observe in real time how many others are in the intention with you.
        </p>
      </div>

      <div className="flex justify-center">
        <button onClick={start} className="btn secondary">
          Start the Journey
        </button>
      </div>
    </IntroPage>
  )
};

export default Step1;